// *****************************************
// VARIABLES
// color and typography variables are stored in there respective files
// *****************************************


// =========================================
// MARGINS
// =========================================
$base-margin: 1.563rem; //25px
$base-margin-row: 6.250rem; //100px
$front-page-row: 9.375rem; //150px

// =========================================
// VIEWPORTS
// =========================================
$viewport-xs: 414px;	 // Phone
$viewport-sm: 768px;   // Tablet Portrait
$viewport-md: 1024px;  // Tablet Landscale
$viewport-lg: 1280px;  // Desktop