// *****************************************
// EXTENDS
// reusable code stored here
// *****************************************


// ==================================
// WORD BREAK
// ==================================
%word-break {
	overflow-wrap: break-word;
	hyphens: auto;
}//word-break

// ==================================
// SIDE NAV MENU 
// ==================================
%menu-side {
	@extend %list-block;
	line-height: 1;
	li {
		border-bottom: 2px solid $grey-light;
		&.current_page_item a {
			text-decoration: none;
			background-color: $blue2;
		}
		h3,
		a {
			@extend %bold-header;
			display: block;
			padding: 1.1250rem $base-margin;
			background: $blue;
			color: $white;
			font-size: 1.65rem;
			margin: 0;
		}
		h3 {
			background: $blue;
		}
		ul {
			border-top: 2px solid $grey-light;
			li {
				a {
					font-family: $sans-serif;
					font-weight: $regular;
					font-size: 0.950em;
					letter-spacing: $base-letter-space;
					padding-bottom: 0.950rem;
					padding-top: 0.950rem;
					background: $white;
					color: $grey-dark;
					&:hover {
						background: lighten($blue,10%);
						color: $white;
						text-decoration: underline;
					}
				}
				&.current_page_item a {
					background: lighten($blue,10%);
					color: $white;
					font-weight: $bold;
					text-decoration: none;
				}
			}
		}
	}
}

// ==================================
// VERTICAL PADDING
// ==================================
%vert-padding {
	padding-bottom: $base-margin;
	padding-top: $base-margin;
	// ========================================
	// SM *MIN-WIDTH*
	// ========================================
	@include breakpoint(sm) {
		padding-bottom: $base-margin*2;
		padding-top: $base-margin*2;
	}//sm

	// ========================================
	// MD *MIN-WIDTH*
	// ========================================
	@include breakpoint(md) {
		padding-bottom: $base-margin-row;
		padding-top: $base-margin-row;
	}//md
}//vertical-padding

// ==================================
// BACKGROUND TEXTURE
// ==================================
%texture {
	background-color: $grey-light;
	background-image: url(img/front-row2-texture.png);
	background-repeat: repeat;
	background-size: auto;
}

// ==================================
// BACKGROUND COVER
// ==================================
%cover {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 50%;
}

// ==================================
// BACKGROUND CONTAIN
// ==================================
%contain {
	background-repeat: no-repeat;
	background-size: contain;
	background-position: 50% 50%;
}

// ==================================
// BLOCK W/ MARGIN
// ==================================
%block {
	display: block;
	margin: 0 0 $base-margin 0;
	clear: both;
	list-style-type: none;
	position: relative;
}

// ==================================
// TEXT SHADOW
// ==================================
%text-shadow {
	text-shadow: 0 0.4rem 1.2rem rgba(#000,0.75);
}

// ==================================
// HEADER KIT
// ==================================
%header {
	font-family: $header-font-family;
	font-weight: $header-font-weight;
	letter-spacing: $header-letter-space;
	line-height: $header-line-height;
	text-transform: $header-case;
}

// ==================================
// BOLD HEADER
// ==================================
%bold-header {
	@extend %header;
	font-weight: $bold;
	text-transform: uppercase;
}

// ==================================
// CONTENT
// ==================================
%content {
	content: "";
	display: block;
	pointer-events: none;
}

// ==================================
// INSIDE
// ==================================
%inside {@include inside;}

// ==================================
// BLOCK IMAGE
// ==================================
%block-image {
	@include img(Block);
}

// ==================================
// LIST - ITEM 
// ==================================
%list-item {
	display: list-item;
	margin-left: $base-margin*1.5;
	margin-bottom: $base-margin/2;
	ul, ol {
		padding-top: $base-margin/2;
		margin-bottom: 0;
		li {
			margin-left: $base-margin;
		}
	}
}

// ==================================
// LISTS
// ==================================
%list-bullet {@include list(bullet);}
%list-inline {@include list(inline);}
%list-digit {@include list(digit);}
%list-block {@include list(block);}

// ==================================
// TRANSITION - DEFAULT
// ==================================
%transition {
	@include transition;
}

// ==================================
// SIMPLE CTA
// ==================================
%simple-cta {
	display: inline-block;
	text-decoration: none;
	position: relative;
	padding-bottom: $base-margin/4;
	text-transform: uppercase;
	line-height: 1;
	border-bottom: 2px solid #e3e3e3;
	font-weight: $semibold;
	&:hover {
		text-decoration: none;
		color: $blue;
		border-bottom-color: $blue;
	}
}

// ==================================
// BUTTON / CTA
// ==================================
%btn {
	@include button;
	background: $base-cta-background;
	color: $base-cta-text;
	&:hover {
		background: $base-cta-hover-background;
		color: $base-cta-text;
	}
}

// ==================================
// BASE FORM STYLE
// ==================================
%base-form {
	display: block;
	background: $white !important;
	color: $base-text-color !important;
	font-family: $base-font-family !important;
	line-height: 1.4 !important;
	font-size: 18px !important;
	width: 100%;
	height: 3.125rem;
	border: 2px solid $grey-medium;
	outline: none !important;
	box-shadow: none !important;
	border-radius: 0 !important;
	letter-spacing: $base-letter-space !important;
	appearance: none;
	&.error {border-color: $red;}
}

// ==================================
// CLEAR FIX
// ==================================
.clear,
.clearfix {
	clear: both;
	&:after {
		visibility: hidden;
		display: block;
		font-size: 0;
		content: " ";
		clear: both;
		height: 0;
	}
}