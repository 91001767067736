// *****************************************
// BASE
// site-wide constructed code
// *****************************************


// =========================================
// HTML, BODY
// =========================================
html,
body {
	font-family: $base-font-family;
	font-size: $base-font-size;
	font-weight: $base-font-weight;
	background-color: $base-background-color;
	color: $base-text-color;
	letter-spacing: $base-letter-space;
	line-height: $base-line-height;
	min-height: 100vh;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html {
	font-size: 75%;
}//html,body

// ========================================
// XS *MIN-WIDTH*
// ========================================
@include breakpoint(xs) {
	body {font-size: 17px;}
	html {font-size: 80%;}
}//xs

// ========================================
// SM *MIN-WIDTH*
// ========================================
@include breakpoint(sm) {
	body {font-size: 18px;}
	html {font-size: 85%;}
}//sm

// ========================================
// MD *MIN-WIDTH*
// ========================================
@include breakpoint(md) {
	body {font-size: 19px;}
	html {font-size: 90%;}
}//md

// ========================================
// LG *MIN-WIDTH*
// ========================================
@include breakpoint(lg) {
	body {font-size: 20px;}
	html {font-size: 100%;}
}//lg


// =========================================
// CALL TO ACTION
// =========================================
a {
	color: $base-link-color;
	text-decoration: none;
	cursor: pointer !important;
	&:hover {
		color: $base-link-hover-color;
		text-decoration: underline;
	} //hover
} //a

.btn {
	@extend %btn;
	&.sm {
		padding: 0.750rem 0.875rem;
	}
	&.black {
		background: #000;
		&:hover {
			background: #222;
		}
	}
	&.blue {
		background: $blue;
		&:hover {
			background: #567eb0;
		}
	}
}

// =========================================
// IMAGES
// =========================================
svg,
img {
	image-rendering: -webkit-optimize-contrast;
	@include img(Inline);
} //img inline
img.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto;
}//wp-align-center
img.block {@extend %block-image;} //img block
.no-touch {pointer-events: none;}

// =========================================
// LISTS
// =========================================
#wrapper {
	ul {@extend %list-bullet;}
	ul.inline {@extend %list-inline;}
	ol {@extend %list-digit;}
}//container
// Dev: wrapped in #wrapper to prevent edits to header & toolbar lists.

// =========================================
// TYPE HTML TAGS
// =========================================
small,.small {font-size: $base-small-font-size;}//small
p {
	@extend %block;
	&:last-child {margin-bottom: 0;}
} //p
b,bold,strong {font-weight: $bold;} //bold
em,i,italic,italics {font-style: italic;} //italic

hr {
	pointer-events: none;
	display: block;
	clear: both;
	width: 100%;
	margin: $base-margin 0;
	width: 100%;
	height: 3px;
	border: none;
	background: $grey-light;
}

// =========================================
// HEADERS
// =========================================
.h1,h1 {font-size: $header-h1-font-size*0.7;}
	@include breakpoint(sm) { .h1,h1 {font-size: $header-h1-font-size;} }//sm min
.h2,h2 {font-size: $header-h2-font-size*0.75;}
@include breakpoint(sm) { .h2,h2 {font-size: $header-h2-font-size;} }//sm min
.h3,h3 {font-size: $header-h3-font-size;}
.h4,h4 {font-size: $header-h4-font-size;}
.h5,h5 {font-size: $header-h5-font-size;}
.h6,h6 {font-size: $base-font-size;}

.h1,.h2,.h3,.h4,.h5,.h6,
h1,h2,h3,h4,h5,h6 {
	@extend %block;
	@extend %header;
	color: $grey-dark;
	&.white {color: $white;}
	&.red {color: $red-dark};
	&.blue {color: $blue;}
}


// =========================================
// CONTAINERS / FRAMEWORK
// =========================================
#container {
	clear: both;
	position: relative;
	overflow: hidden;	
}//container

.inside {
	@include inside;
}//inside

.wrap-x {
	&.pl {padding-left: env(safe-area-inset-left);}
	&.pr {padding-right: env(safe-area-inset-right);}
	&.pt {padding-top: env(safe-area-inset-top);}
	&.pb {padding-bottom: env(safe-area-inset-bottom);}
	&.ml {margin-left: env(safe-area-inset-left);}
	&.mr {margin-right: env(safe-area-inset-right);}
	&.mt {margin-top: env(safe-area-inset-top);}
	&.mb {margin-bottom: env(safe-area-inset-bottom);}
}//wrap-x