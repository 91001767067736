@font-face {
  font-family: 'fontello';
  src: url('fonts/fontello.eot?92252589');
  src: url('fonts/fontello.eot?92252589#iefix') format('embedded-opentype'),
       url('fonts/fontello.woff2?92252589') format('woff2'),
       url('fonts/fontello.woff?92252589') format('woff'),
       url('fonts/fontello.ttf?92252589') format('truetype'),
       url('fonts/fontello.svg?92252589#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
 
 .fontello-icon:before, [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
 
.icon-twitter:before { content: '\f099'; }
.icon-youtube:before { content: '\f16a'; }
.icon-instagram:before { content: '\f16d'; }
.icon-facebook:before { content: '\f09a'; }
.icon-linkedin:before { content: '\f0e1'; }

.icon-left:before { content: '\e800'; }
.icon-right:before { content: '\e801'; }
.icon-down:before { content: '\e808'; }
.icon-up:before { content: '\e809'; }

.icon-phone:before { content: '\e804'; }
.icon-gps:before { content: '\e805'; }
.icon-search:before { content: '\e806'; }
.icon-mail:before { content: '\f0e0'; }
.icon-calendar:before { content: '\e802'; }