// *****************************************
// TEMPLATE PARTS
// views, modules, plugins
// *****************************************


// ==================================
// SOCIAL MEDIA LIST
// ==================================
.menu.inline.social {
	clear: both;	
	margin-bottom: $base-margin/1.5;
	margin-top: $base-margin/1.5;
	margin-left: -$base-margin/4;
	margin-right: -$base-margin/4;
	li {
		margin-left: $base-margin/4;
		margin-right: $base-margin/4;
		a {
			@extend %transition;
			display: block;
			width: 3.375rem;
			height: 3.375rem;
			background: #8f8f8f;
			color: $white;
			text-decoration: none;
			position: relative;
			overflow: hidden;
			line-height: 1;
			cursor: pointer;
			&:hover {
				background: $white;
				color: $grey-dark;
				&:before {color: $grey-dark;}
			}
			&:before {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translateX(-50%) translateY(-50%);
				font-size: 2.0rem;
				color: $white;
			}
		}
	}
	
}

// ==================================
// HERO
// ==================================
.page-hero {
	@extend %cover;
	clear: both;
	background-color: $red-dark;
	background-image: url(img/red-hero.jpg);
	padding-bottom: $base-margin-row/2;
	padding-top: $base-margin-row/2;	
	background-color: $grey-medium;
	color: $white;
	
	.h1,.h2,
	h1,h2 {color: $white;}
	
	&.white-text {
		//default
	}
	
	&.blue-text {
		color: $grey-dark;
		.h1,h1 {color: $blue;}
		.h2,h2 {color: $grey-dark;}
	}
	
	h1 {
		@extend %bold-header;
		@extend %word-break;
		margin-bottom: 0;
	}
	
	.h2,h2 {
		margin-bottom: 0;
		margin-top: $base-margin/4;
	}
	
	// ========================================
	// SM *MIN-WIDTH*
	// ========================================
	@include breakpoint(sm) {

	}//sm

	// ========================================
	// MD *MIN-WIDTH*
	// ========================================
	@include breakpoint(md) {
		padding-bottom: $base-margin-row;
		padding-top: $base-margin-row;
	}//md

	// ========================================
	// LG *MIN-WIDTH*
	// ========================================
	@include breakpoint(lg) {
	}//lg
	
}//hero

// ==================================
// BODY-ROW
// ==================================
.body-row {
	margin-top: $base-margin;
	clear: both;
	.box {
		background: $white;
		padding: $base-margin;
		.wrap {
			max-width: 768px;
			margin: 0 auto;
			h2 {
				text-transform: uppercase;
				color: $grey-dark;
			}
		}
	}
	
	// ========================================
	// SM *MIN-WIDTH*
	// ========================================
	@include breakpoint(sm) {
		.box {
			padding-bottom: $base-margin*2;
			padding-top: $base-margin*2;
		}
	}//sm

	// ========================================
	// MD *MIN-WIDTH*
	// ========================================
	@include breakpoint(md) {
		.box {
			padding-bottom: $base-margin-row;
			padding-top: $base-margin-row;
		}
	}//md

	// ========================================
	// LG *MIN-WIDTH*
	// ========================================
	@include breakpoint(lg) {
	}//lg
	
}//body-row

// ==================================
// NEIGHBORHOOD - REPEATING BLOCKS
// ==================================
.content-blocks-row {
	background: $white;
	padding-top: $base-margin;
	
	.content-blocks-col {
		clear: both;
		border-bottom: 0.313rem solid $grey-light;
		margin-bottom: $base-margin;
		padding-bottom: $base-margin;
		&:last-child {
			margin-bottom: 0;
		}
	}
	
	.col-img {
		p {
			position: relative;
			z-index: 2;
			pointer-events: none;
			margin-bottom: 1.125rem;
			margin-top: 1.125rem;
			margin-right: 1.125rem;
			&:before {
				@extend %content;
				position: absolute;
				top: -1.125rem;
				left: 1.125rem;
				height: 1.125rem;
				width: 60%;
				background: $grey-light;
				z-index: -1;
			}
			&:after {
				@extend %content;
				position: absolute;
				bottom: -1.125rem;
				right: -1.125rem;
				height: 3.375rem;
				width: 60%;
				background: $grey-medium;
				z-index: -1;
			}
		}
		&.img-right {
			@extend .last-sm;
		}
	}
	
	//flex grid stuff
	.row {
		margin-bottom: -$base-margin;
		.col {margin-bottom: $base-margin;}
	}
	
	// ========================================
	// SM *MIN-WIDTH*
	// ========================================
	@include breakpoint(sm) {
		padding-top: $base-margin*2;
		
		.content-blocks-col {
			margin-bottom: $base-margin*2;
			padding-bottom: $base-margin*2;
		}
		
		.row {
			margin-bottom: 0;
			.col {margin-bottom: 0;}
		}
		
	}//sm

	// ========================================
	// MD *MIN-WIDTH*
	// ========================================
	@include breakpoint(md) {
		padding-top: $base-margin-row;
		
		.content-blocks-col {
			margin-bottom: $base-margin-row;
			padding-bottom: $base-margin-row;
		}
		
	}//md

	// ========================================
	// LG *MIN-WIDTH*
	// ========================================
	@include breakpoint(lg) {
		
		.row {
			.col {
				padding-left: $base-margin*2;
				padding-right: $base-margin*2;
			}
		}
		
	}//lg
	
}//content-blocks-row

// ==================================
// SITE MAP
// ==================================
#container .site-map-wrap {
	
	ul.menu {margin-bottom: 0;}
	
	.phone,
	#menu-item-176,
	.home-only {display: none !important;}
	
}//site-map

// ==================================
// AVAILABLE RENTALS IFRAME WRAP
// ==================================
.available-rentals-iframe-wrap {
	padding: 0.616rem;
	clear: both;
	background: #e5e5e5;
	
	iframe {
		display: block;
		width: 100%;
		clear: both;
		border: none;
		margin: 0;
		padding: 0;
	}
	
}//available-rentals-iframe-wrap

// ==================================
// NEIGHBORHOOD FORM
// ==================================
.neighborhood-form {
	padding-bottom: $base-margin;
	padding-top: $base-margin;
	background-color: $blue;
	background-image: url(img/texture-blue.png);
	color: $white;
	
	h2 {
		@extend %bold-header;
	}
	
	.form-wrap {@extend.row; @extend .nested;}
	.form-item {@extend .col-xs-12; @extend .col-sm-6;}
	.form-type-textarea {@extend .col-sm-12;}
	.form-action {@extend .col-xs-12;}
	
	.wpcf7-textarea {
		height: 6.250rem;
	}
	
	.form-item {
		label {
			color: $white;
			.req {color: $white;}
		}
	}
	
	.btn {
		background: $black;
		&:hover {
			background: #000;
		}
	}
	
	// ========================================
	// SM *MIN-WIDTH*
	// ========================================
	@include breakpoint(sm) {
		padding-bottom: $base-margin*2;
		padding-top: $base-margin*2;
		
	}//sm

	// ========================================
	// MD *MIN-WIDTH*
	// ========================================
	@include breakpoint(md) {
	}//md

	// ========================================
	// LG *MIN-WIDTH*
	// ========================================
	@include breakpoint(lg) {
	}//lg
	
}//neighborhood-form

// ==================================
// NEIGHBORHOODS SINGLE MENU
// ==================================
.list-items-neighborhoods-menu {
	@extend %vert-padding;
	background: $white;
	
	h2,h4 {
		margin: 0;
	}
	
	.btn.sm {
		display: flex;
		min-height: 100%;
		justify-content: center;
		flex-direction: column;
		line-height: 1.1;	
		border: 1px solid darken($blue,15%);
	}
	
	.row {
		margin-bottom: -$base-margin;
	}
	
	.col {
		margin-bottom: $base-margin;
	}
	
	// ========================================
	// SM *MIN-WIDTH*
	// ========================================
	@include breakpoint(sm) {
	}//sm

	// ========================================
	// MD *MIN-WIDTH*
	// ========================================
	@include breakpoint(md) {
	}//md

	// ========================================
	// LG *MIN-WIDTH*
	// ========================================
	@include breakpoint(lg) {
		padding-left: $base-margin;
		padding-right: $base-margin;
	}//lg	

}//neighborhoods-single-menu

// ==================================
// GOOGLE MAP WRAP
// ==================================
.google-map-wrap {
	clear: both;
	border: 0.616rem solid $white;
	background: $white;
	margin-bottom: $base-margin;
	
	iframe {
		display: block;
		width: 100%;
		height: 75vw;
		min-height: 250px;
		max-height: 350px;
		border: none;
	}
}//google-map

// ==================================
// REPATING GROUP FIELDS
// ==================================
.repeating-group-wrap {
	clear: both;
	
	.repeating-group-row {
		clear: both;
		margin-top: $base-margin;
		padding-top: $base-margin;
		border-top: 0.313rem solid $grey-light;
		position: relative;
	}
	
	.scroll-anchor {
		position: absolute;
		top: -100px;
		left: 0;
		width: 5px;
		height: 5px;
		background: none;
		z-index: 99;
		pointer-events: none;
		visibility: hidden;
		opacity: 0;
	}
	
	.repeating-group-headline {
		h2 {
			margin-bottom: 0;
			padding-right: 2.875rem;
			color: $blue;
			position: relative;
			span {
				display: inline-block;
				clear: left;
				cursor: pointer;
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
			i {
				@extend %transition;
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateX(0) translateY(-50%) rotate(0);
				cursor: pointer;
				color: $blue;
			}
		}
	}
	
	.repeating-group-content {
		display: none;
		background: $grey-light;
		padding: $base-margin;
		margin-top: $base-margin;
	}
	
	.open {
		.repeating-group-headline {
			h2 {
				i {
					transform: translateX(0) translateY(-50%) rotate(180deg);
				}
			}
		}
	}//open
	
	
}//repeating-group-fields

// ==================================
// ENTRY
// ==================================
article.entry {
	clear: both;
	
	.row {
		.col-top {
			margin-bottom: $base-margin/2;
		}
	}
	
	.date {
		margin-bottom: $base-margin/4;
		span {
			display: inline-block;
			background: $grey-light;
			line-height: 1;
			font-size: 14px;
			padding: $base-margin/2;
			i {margin-right: $base-margin/3;}
		}
	}
	
	.title {
		h3 {margin-bottom: 0;}
	}
	
	.image {
		img {
			@extend .block;
			box-shadow: 0.616rem 0.616rem 0 $grey-light;
			margin-bottom: 0.616rem;
			margin-right: 0.616rem;
		}
	}
	
}//entry

// ==================================
// WIDGET BOX
// ==================================
#container .widget-box {
	margin-bottom: $base-margin;
	
	ul {
		@extend %menu-side;
	}
	
	p.error {
		background: $white;
		padding: $base-margin;
	}
	
}//widget-box

// ==================================
// TESTIMONIALS - LANDING
// ==================================
.list-items-testimonials {
	.row {
		margin-bottom: 0;
		.col {
			margin-bottom: 0;
		}
	}
}//testimonials

// ==================================
// TEAM MEMBERS - LANDING
// ==================================
.list-items-team {
	
	.col {
		min-height: 100%;
	}
	
	.box {
	}
	
	.item {
		clear: both;
	}
	
	.title {
		h3 {
			margin-bottom: 0;
			line-height: 1.1;
		}
		
	}
	
	.image {
		margin-bottom: 1.125rem;
		img {
			box-shadow: 0.616rem 0.616rem 0 $grey-light;
			margin-bottom: 0.616rem;
			margin-right: 0.616rem;
		}
	}
	
	.position {
		font-size: 0.875em;
		margin-top: 0.616rem;
		margin-bottom: 0.616rem;
		line-height: 1.15;
		font-weight: $bold;
		color: $grey-dark;
	}
	
	.phone-text {
		font-size: 0.875em;
		line-height: 1.15;
		margin-top: 0.616rem;
		a {
			color: $black;
		}
	}
	
	// ========================================
	// SM *MIN-WIDTH*
	// ========================================
	@include breakpoint(sm) {
	}//sm

	// ========================================
	// MD *MIN-WIDTH*
	// ========================================
	@include breakpoint(md) {
	}//md

	// ========================================
	// LG *MIN-WIDTH*
	// ========================================
	@include breakpoint(lg) {
		
		.col {
			padding-left: $base-margin/1.5;
			padding-right: $base-margin/1.5;
		}
		
	}//lg
	
}//team-members-landing

// ==================================
// TEAM PAGE ITEM
// ==================================
.team-page-item {
	&.phone {
		pointer-events: none;
		a {color: $black;}
	}
	a {
		display: inline-block;
		position: relative;
		min-height: 2.188rem;
		padding-left: 2.813rem;
	}
	i {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 2.188rem;
		height: 2.188rem;
		text-align: center;
		background: $blue;
		color: $white;
		font-size: 1.125rem;
		&:before {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-52%);
			line-height: 0.8;
		}
	}
}//team-member-item

// ==================================
// EMERGENCY
// ==================================
.emergency {
	background: $red;
	color: $white;
	padding: $base-margin;
	a {
		color: $white;
	}
}