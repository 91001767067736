// *****************************************
// COLORS
// defined site colors
// *****************************************


// ==================================
// COLOR VARIABLES
// ==================================
$black: #4f4e4f;
$white: #FFFFFF;

$red-dark: #b4252d;
$red: #d7343d;

$blue-dark: #506786;
$blue: #617ca2;
$blue2: #567eb0;

$grey-light: #f3f3f3;
$grey-medium: #d4d4d4;
$grey-dark: #7e7d7f;

// ==================================
// BASE COLORS
// ==================================
$base-background-color: $grey-light;
$base-text-color: $black;
$base-link-color: $blue;
$base-link-hover-color: $blue;
$base-headline-color: $black;
$base-cta-background: $red-dark;
$base-cta-hover-background: lighten($red-dark, 5%);
$base-cta-text: $white;

// ==================================
// SELECTION / HIGHLIGHTS
// ==================================
::selection {
	background: $black;
	color: $white;
}