// *****************************************
// TYPOGRAPHY
// *****************************************


// =========================================
// DEFINED FONTS
// =========================================
$sans-serif: "proxima-nova",sans-serif; //400,500,700
$sans-serif-headline: "korolev-compressed",sans-serif; //500,700

// =========================================
// FONT WEIGHTS
// =========================================
$regular: 400;
$semibold: 500;
$bold: 700;

// =========================================
// BASE FONT STYLES + SIZE
// =========================================
$base-font-family: $sans-serif;
$base-font-weight: $regular;
$base-font-size: 16px;
$base-line-height: 1.5;
$base-letter-space: 0.031rem;

// =========================================
// HEADING FONT SIZES
// =========================================
$header-h1-font-size: 5.0rem; 	//80px
$header-h2-font-size: 2.875rem; //46px
$header-h3-font-size: 2.250rem; //36px
$header-h4-font-size: 1.5rem; //24px
$header-h5-font-size: 1rem;

// =========================================
// FRONT PAGE HEADING FONT SIZES
// =========================================
$front-header-h1-font-size: 11.250rem; // 180px
$front-header-h2-font-size: 7.5rem;    // 120px
$front-header-h3-font-size: 2.875rem;  // 46px
$front-header-h4-font-size: 1.875rem;  // 30px
$front-header-h5-font-size: 1.375rem;  // 22px

// =========================================
// HEADING FONT STYLES
// =========================================
$header-font-family: $sans-serif-headline;
$header-font-weight: $semibold;
$header-line-height: 1.2;
$header-letter-space: 0.125rem;
$header-case: none;

// =========================================
// CALL TO ACTION
// =========================================
$base-cta-font-size: $front-header-h5-font-size;
$base-cta-font-weight: $semibold;

// =========================================
// MISC FONT VARIABLES
// =========================================
$base-small-font-size: 14px;