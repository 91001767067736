// *****************************************
// INTERNAL PAGES
// *****************************************

// ========================================
// MAIN AREA
// ========================================
.main-area {
	clear: both;
	padding-bottom: $base-margin;
	padding-top: $base-margin;	
	
	// ========================================
	// SM *MIN-WIDTH*
	// ========================================
	@include breakpoint(sm) {
		padding-bottom: $base-margin*2;
	}//sm

	// ========================================
	// MD *MIN-WIDTH*
	// ========================================
	@include breakpoint(md) {
		padding-bottom: $base-margin-row;
	}//md

	// ========================================
	// LG *MIN-WIDTH*
	// ========================================
	@include breakpoint(lg) {
		margin-top: -$base-margin*2;
	}//lg
	
}//main-ara

// ========================================
// BASIC PAGE BOX
// ========================================
.basic-page-box {
	background: $white;
	padding: $base-margin;
	min-height: 100%;
	
	// ========================================
	// SM *MIN-WIDTH*
	// ========================================
	@include breakpoint(sm) {
		padding: $base-margin*2;
	}//sm

	// ========================================
	// MD *MIN-WIDTH*
	// ========================================
	@include breakpoint(md) {
	}//md

	// ========================================
	// LG *MIN-WIDTH*
	// ========================================
	@include breakpoint(lg) {
	}//lg
	
}//basic-page-box

// ========================================
// MAIN AREA - BASIC PAGE
// ========================================
.main-basic-page {
	.row {
		margin-bottom: -$base-margin;
		.col {
			margin-bottom: $base-margin;
		}
	}
	
	aside {
		margin-bottom: -$base-margin;
		> .widget {
			margin-bottom: $base-margin;
		}
	}
	
}//main-area-basic-page

// ========================================
// SINGLE - TEAM
// ========================================
.single-team {
	.item.image {
		img {
			border: 0.616rem solid $white;
		}
	}
}