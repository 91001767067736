// ==================================
// HEADER
// ==================================
header#header {
	background: $white;
	padding-bottom: $base-margin/2;
	padding-top: $base-margin/2;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 98;
	border-bottom: 1px solid #d5d5d5;
	
	a.logo {
		display: block;
		width: 8.500rem;
		height: 3.938rem;
		img {
			display: block;
			width: 8.500rem;
			height: 3.938rem;
		}
	}
	
	.col-logo {
		width: 11.625rem;
		max-width: 11.625rem;
		flex: 0 0 11.625rem;
		padding-left: $base-margin;
		padding-right: $base-margin;
	}
	
	#menu-burger {
		@extend %contain;
		display: block;
		background-image: url(img/burger.svg);
		width: 40px;
		height: 40px;
		cursor: pointer;
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%) translateX(0);
	}
	
	.menu-wrap {
		margin: auto 0;
		width: 100%;
		line-height: 1;
		ul {
			margin: 0;
			padding: 0 $base-margin 0 0;
			list-style-type: none;
			display: block;
			clear: both;
			line-height: 1;
			> li {
				display: inline-block;
				margin: 0 0 0 $base-margin;
				position: relative;
				line-height: 1;
				list-style-type: none;
				&:first-child {margin-left: 0;}
				a {
					display: block;
					text-decoration: none;
					color: $grey-dark;
					line-height: 1.15;
				}
			}
		}
	}
	
	.menu.utility {
		margin-bottom: 0;
		padding-right: 40px;
		font-size: 1.75rem;
		position: relative;
		clear: both;
		@extend .clearfix;
		a {
			color: $black;
			position: relative;
			&:hover {text-decoration: underline;}
		}
		.phone {
			font-weight: $semibold;
			display: block;
			float: right;
			clear: both;
			a {
				height: 40px;
				overflow: hidden;
				width: 26px;
				text-indent: 9999px;
				color: $grey-dark;
				text-decoration: none;
				&:before {
					@extend %content;
					@extend %contain;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					background-color: #FFF;
					background-image: url(img/header-utility-phone.svg);
					z-index: 1;
				}
			}

		}//phone
		.icon {
			display: none;
			a {
				&:before {
					@extend %content;
					@extend %contain;
					position: absolute;
					top: 0;
					left: 0;
					width: auto;
					height: 20px;
				}
			}
		}
		.login {
			a {
				padding-left: 29px;
				&:before {
					background-image: url(img/header-utility-icon1.svg);
					width: 19px;
				}
			}
		}
		.resources {
			a {
				padding-left: 37px;
				&:before {
					background-image: url(img/header-utility-icon2.svg);
					width: 27px;
				}
			}
		}
		
	}//menu.utility
	
	.menu.main {
		font-size: 1.063rem;
		display: none;
		ul > li {
			text-transform: uppercase;
			font-weight: $semibold;
		}
		ul > li:hover > a {color: $blue;}
		ul > li:hover > ul {
			visibility: visible;
			opacity: 1;
		}
		ul > li > ul {
			position: absolute;
			top: 100%;
			left: -1rem;
			width: 16.875rem;
			text-align: left;
			visibility: hidden;
			opacity: 0;
			padding-top: 1.9rem;
			margin-top: -1px;
			> li {
				display: block;
				margin: 0;
				font-weight: $semibold;
				font-size: 0.875rem;
				border-left: 1px solid darken($blue,15%);
				border-right: 1px solid darken($blue,15%);
				border-bottom: 1px solid darken($blue,15%);
				> a {
					background: $blue;
					color: $white;
					padding: 1.250rem 1rem 1.125rem;
				}
			}
			> li:hover > a {
				color: $white;
				background: $blue2;
			}//li:hover>a
		}//ul>li>ul
		
		ul > li.two-col-menu > ul {
			width: 33.750rem;
			left: 50%;
			transform: translateX(-50%) translateY(0);
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			flex: 0 0 auto;
			> li {
				width: 50%;
				min-height: 100%;
				margin-right: -1px;
				a {
					min-height: 100%;
				}
			}
		}
		
	}//menu.manu
	
	// ========================================
	// SM *MIN-WIDTH*
	// ========================================
	@include breakpoint(sm) {
		.menu.utility {
			.phone {
				display: inline-block;
				float: none;
				clear: none;
				a {
					width: auto;
					height: auto;
					overflow: visible;
					text-indent: 0;
					&:before {display: none;}
				}
			}
		}
	}//sm

	// ========================================
	// MD *MIN-WIDTH*
	// ========================================
	@include breakpoint(md) {
	}//md

	// ========================================
	// LG *MIN-WIDTH*
	// ========================================
	@include breakpoint(lg) {
		padding-bottom: $base-margin;
		padding-top: $base-margin;
		.menu.main {display: block;}
		.menu.utility {
			margin-bottom: $base-margin/1.5;
			padding-right: 0;
			font-size: 1.125rem;
			.icon {display: inline-block;}
			.phone a {color: $blue;}
		}
		#menu-burger { display: none; }
		.menu-wrap {
			ul {
				padding-right: 0;
			}
		}
	}//lg
	
}//header

// ==================================
// HEADER POPUP
// ==================================
#header-popup {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba($black,0.9);
	z-index: 99;
	display: none;
	.inside {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		max-width: none;
		background: $white;
		overflow-x: hidden;
		overflow-y: scroll;
		padding: 0;
		margin: 0;
		a {
			color: $blue;
			text-decoration: none;
		}
		
		.row.close-row {
			padding-bottom: $base-margin/2;
			padding-top: $base-margin/2;
			border-bottom: 1px solid $grey-medium;
		}
		
		a.logo {
			display: block;
			width: 8.500rem;
			height: 3.938rem;
			img {
				display: block;
				width: 8.500rem;
				height: 3.938rem;
			}
		}
		
		.menu {
			ul {
				margin: 0;
				padding: 0;
				clear: both;
				line-height: 1.15;
				li {
					@extend %transition;
					display: block;
					list-style-type: none;
					margin: 0;
					padding: 0 $base-margin;
					border-bottom: 1px solid $grey-medium;
					a {
						@extend %transition;
						display: block;
						padding: $base-margin 0;
						text-transform: uppercase;
						font-weight: $bold;
						font-size: $front-header-h4-font-size;
						&:hover {text-decoration: underline;}
					}
					ul {
						li {
							border-bottom: none;
						}
					}
				}
			}
		}
		
		#header-popup-close {
			@extend %contain;
			width: 35px;
			height: 35px;
			background-image: url(img/close.svg);
			cursor: pointer;
			margin: auto;
			margin-right: 0;
		}
		
		.menu.main {
			ul {
				li {
					&.current-menu-ancestor,
					&.current-menu-item {
						background: $blue;
						ul {display: block;}
						a {color: $white;}
					}
					a {
					}
					ul {
						display: none;
						margin-top: -$base-margin/2;
						padding-bottom: $base-margin/1.5;
						li {
							padding: 0;
							a {
								text-transform: none;
								font-weight: $semibold;
								font-size: 1.5rem;
								padding: $base-margin/4 0;
								border-bottom: none;
								color: $grey-dark;
							}
						}
					}
				}
			}//ul
		}//menu.main
		
		.menu.utility {
			ul {
				li {
					&.phone,
					&.icon {
						position: relative;
						&.resources a:before {background-image: url(img/header-utility-icon2.svg);}
						&.login a:before {background-image: url(img/header-utility-icon1.svg);}
						a {
							position: relative;
							padding-left: 3rem;
							&:before {
								@extend %content;
								@extend %contain;
								width: 2rem;
								height: 2rem;
								position: absolute;
								top: 50%;
								left: 0;
								transform: translateY(-50%) translateX(0);
							}
						}
					}
					a {
						text-transform: none;
						font-weight: $semibold;
						color: $grey-dark;
					}
					&.phone a:before {background-image: url(img/header-utility-phone.svg);}
				}//li
			}
		}//menu.utility
		
	}//inside
	
	// ========================================
	// SM *MIN-WIDTH*
	// ========================================
	@include breakpoint(sm) {
	}//sm

	// ========================================
	// MD *MIN-WIDTH*
	// ========================================
	@include breakpoint(md) {
		.inside {
			max-width: 600px;
		}
	}//md

	// ========================================
	// LG *MIN-WIDTH*
	// ========================================
	@include breakpoint(lg) {
	}//lg
	
}//header-popup

// ==================================
// FOOTER
// ==================================
footer#footer {
	background-color: #787779;
	background-image: url(img/footer-bkg.png);
	background-repeat: repeat;
	background-position: 50% 50%;
	color: $white;
	font-size: 0.875em;
	padding-bottom: $base-margin*1.25;
	padding-top: $base-margin*2;
	a {
		color: $white;
	}
	
	.box {
		display: block;
		flex: none;
		width: 100%;
	}
	
	.widget-wrap {
		margin-bottom: $base-margin/2;
	}
	
	.social-wrap {
		margin-bottom: 1.125rem;
		margin-top: 1.125rem;
	}
	
	p {
		margin-bottom: $base-margin/2;
		&:last-child {margin-bottom: 0;}
	}
	
	div.logo {
		max-width: 136px;
		margin-bottom: $base-margin/4;
		margin-left: auto;
		margin-right: auto;
		margin-top: 0;
		a {
			display: block;
			img {
				max-width: 136px;
			}
		}
	}
	
	.menu.footer {
		ul {
			@extend %list-inline;
			margin-bottom: 0;
			li {
				display: block;
				margin-right: 0;
			}
			li.home-only {display: none;}
		}
	}
	
	// ========================================
	// SM *MIN-WIDTH*
	// ========================================
	@include breakpoint(sm) {
	}//sm

	// ========================================
	// MD *MIN-WIDTH*
	// ========================================
	@include breakpoint(md) {
		div.logo {
			margin-left: auto;
			margin-right: 0;
		}
		
		p.copyright {
			br {display: none;}
		}
		
		.social-wrap {
			margin-top: 0;
		}
		
		.menu.footer {
			ul {
				li {
					display: inline-block;
					margin-right: $base-margin;
				}
			}
		}
	}//md

	// ========================================
	// LG *MIN-WIDTH*
	// ========================================
	@include breakpoint(lg) {
	}//lg
	
}

// ==================================
// HIDE FOOTER MENU WITH NOT ON FRONT
// ==================================
body.home footer#footer .menu.footer ul li.home-only {display:inline-block;}

// ==================================
// PAGINATION
// ==================================
.pagination {
	text-align: center;
	clear: both;
	margin: -0.313rem;
	
	.page-numbers {
		@extend .btn;
		@extend .sm;
		@extend .blue;
		margin: 0.313rem;
		text-align: center;
		&.current {
			background: $grey-light;
			color: $black;
			cursor: text;
		}
	}
	
}//pagination


// ==================================
// SIDEBAR MENU
// ==================================
#container .menu-widget {
	@extend %menu-side;	
}//sidebar-menu

// ==================================
// CATEGORY MENU
// ==================================
.category-menu,
.list-items-neighborhoods {
	
	.row {
		margin-bottom: -$base-margin;
		&.nested {
			margin-left: -$base-margin/2;
			margin-right: -$base-margin/2;
		}
	}
	
	.col {
		margin-bottom: $base-margin;
		padding-left: $base-margin/2;
		padding-right: $base-margin/2;
	}
	
	.box {
		background: $white;
		padding: $base-margin;
		min-height: 100%;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		flex: 0 0 auto;
		max-width: 100%;
	}
	
	.item {
		display: block;
		width: 100%;
		flex: none;
		clear: both;
		margin: 0 0 $base-margin 0;
	}
	
	.title {
		margin-bottom: $base-margin/2;
		h3 {
			margin-bottom: 0;
			line-height: 1.15;
		}
	}
	
	.link {
		margin-top: auto;
		margin-bottom: 0;
		.btn {
			display: block;
			text-align: center;
		}
	}	
	
}//category-menu

// ==================================
// NEIGHBORHOODS MENU
// ==================================
.list-items-neighborhoods {
	
	.row {
		margin-bottom: -$base-margin;
		&.nested {
			margin-left: -$base-margin;
			margin-right: -$base-margin;
		}
	}
	
	.col {
		margin-bottom: $base-margin;
		padding-left: $base-margin;
		padding-right: $base-margin;
	}
	
	// ========================================
	// SM *MIN-WIDTH*
	// ========================================
	@include breakpoint(sm) {
		
		.row {
			margin-bottom: -$base-margin*2;
		}

		.col {
			margin-bottom: $base-margin*2;
		}
		
	}//sm
	
}//neighborhoods-menu

// SIDE MENU PAGE-LIST
#wrapper ul.page-list  {
	@extend %menu-side
}