// *****************************************
// FRONT PAGE
// welcome home
// *****************************************


// =========================================
// FRONT ROW
// =========================================
.front-row {
	@extend %cover;
	clear: both;
	padding-bottom: $base-margin;
	padding-top: $base-margin;
	overflow: hidden;
	
	.h1,h1 {
		font-size: $front-header-h1-font-size*0.6;
		line-height: 0.9;
	}
	.h2,h2 {
		font-size: $front-header-h2-font-size*0.65;
		line-height: 0.92;
	}
	.h3,h3 {
		font-size: $front-header-h3-font-size;
	}
}//front row

// =========================================
// ROW 1
// =========================================
#row1 {
	background-color: #d6cdcb;
	color: $white;
	padding-bottom: 0;
	padding-top: 0;
	background-position: 50% 50%;
	background-blend-mode: multiply;
	
	h1 {
		@extend %bold-header;
		@extend %text-shadow;
		margin-bottom: 0;		
	}
	
	.cta-text {
		font-style: italic;
		font-size: $front-header-h5-font-size;
		margin-bottom: $base-margin/2;
	}
	
	.row-top {
		margin-bottom: $front-page-row/2;
		margin-top: $front-page-row/2;
	}
	
	.row1-cta {
		position: relative;
		background: rgba($red-dark,0.85);
		padding: $base-margin;
		margin-left: -$base-margin;
		margin-right: -$base-margin;
		&:before {
			@extend %content;
			display: none;
		}
		.btn {
			background: $red;
			&:hover {
				background: lighten($red,2.5%);
			}
		}
	}
	
}//row1

// =========================================
// ROW 2
// =========================================
#row2 {
	@extend %texture;
	padding-left: $base-margin;
	padding-right: $base-margin;
	
	.inside {
		box-shadow: 0 0 1.250rem rgba(#000,0.05);
		background: $white;
	}
	
	.col {
		background-color: $white;
		padding-bottom: $base-margin;
		padding-top: $base-margin;
	}
	
	.col-bkg {
		@extend %cover;
		min-height: 50vw;
		pointer-events: none;
	}
	
	h2.headline {
		@extend %text-shadow;
		@extend %bold-header;
		color: #fbfbfb;
		display: inline-block;
		line-height: 0.65;
		margin-bottom: 0;
	}
	
	.address-number {
		@extend %bold-header;
		color: $grey-medium;
		margin-bottom: $base-margin/2;
		line-height: 0.65;
	}
	
	.address-street {
		color: $blue;
		font-size: $front-header-h4-font-size;
		font-weight: $bold;
		margin-bottom: $base-margin/4;
	}
	
	.cta-slogan {
		strong {text-transform: uppercase;}
	}
	
	.cta-wrap {
		margin-bottom: 0;
	}
	
}
//row2

// =========================================
// ROW 3
// =========================================
#row3 {
	background-position: right center;
	background-color: $black;
	background-blend-mode: multiply;
	
	h2.headline {
		@extend %bold-header;
		@extend %text-shadow;
		color: $white;
		.inline-logo {
			width: 10.313rem;
			height: auto;
			vertical-align:middle;
			box-shadow: 0 0 0.625rem rgba(#000,0.7);
		}
	}
	
	.repeat-wrap {
		clear: both;
		counter-reset: my-row3-counter;
		font-size: $front-header-h5-font-size;
		.repeat-item {
			&:after {
				@extend %content;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				width: 4.375rem;
				background: $grey-dark;
				z-index: 1;
			}
			&:before {
				content: counter(my-row3-counter);
				counter-increment: my-row3-counter;
				@extend %header;
				font-weight: $bold;
				font-size: $front-header-h3-font-size;
				position: absolute;
				top: 50%;
				transform: translateX(0) translateY(-50%);
				left: 0;
				width: 4.370rem;
				background: $grey-dark;
				color: $white;
				text-align: center;
				z-index: 2;
				pointer-events: none;
				font-style: normal;
				line-height: 0.65;
			}
			&:last-child {
				margin-bottom: 0;
			}
			margin-bottom: 4px;
			background: rgba(#FFF,0.95);
			padding: $base-margin 1rem;
			padding-left: 5.313rem;
			line-height: 1.2;
			font-style: italic;
			position: relative;
			font-weight: $semibold;
			box-shadow: 0 0 6px rgba(#000,0.1);
		}
	}
	
	.col {
		display: flex;
		flex-direction: column;
		&.col-list {
			margin-top: $base-margin;
		}
	}
	
	.box {
		display: block;
		margin: auto 0;
		width: 100%;
	}
	
}//row3

// =========================================
// ROW 4
// =========================================
#row4 {
	background-position: 62% 0;
	padding-bottom: $base-margin*2;
	padding-top: $base-margin*2;
	background-color: $white;
	
	.text {
		margin-bottom: $base-margin;
	}
	
	h3 {
		color: $grey-dark;
		@extend %bold-header;
	}
	
	@media (max-width: $viewport-sm - 1px) {
		background-image: none !important;
		border-bottom: 4px solid $grey-light;
	}
	
}//row4

// =========================================
// ROW 5
// =========================================
#row5 {
	@extend %texture;
	padding-left: $base-margin;
	padding-right: $base-margin;
	
	.inside {
		background: $white;
		box-shadow: 0 0 1.250rem rgba(#000,0.05);
		position: relative;
	}
	
	.inside-top {
		margin-bottom: 0;
		border-bottom: 4px solid $grey-light;
	}
	
	.inside-bottom {
		.col {
			font-size: 1.25rem;
			line-height: 1.3;
			strong {text-transform: uppercase;}
		}
		.name {
			margin-bottom: $base-margin/1.5;
			span {
				display: block;
				clear: both;
				margin: 0.375rem 0;
			}
		}
		.quote {
			position: relative;
			p {
				position: relative;
				z-index: 3;
			}
			i {
				position: absolute;
				display: block;
				pointer-events: none;
				background-image: url(img/front-quote.png);
				background-repeat: no-repeat;
				background-size: contain;
				width: 4.0rem;
				height: 3.313rem;
				overflow: hidden;
				text-indent: 999px;
				&.top {
					top: -0.5rem;
					left: -1rem;
				}
				&.bottom {
					bottom: -0.5rem;
					right: -1rem;
				}
			}
		}
		.col-profile {
			width: 98px;
			flex: 0 0 98px;
			padding-right: 0;
		}
		.h3 {
			font-weight: $bold;
			text-transform: none;
			font-size: 1.875rem;
			color: $grey-dark;
			line-height: 1.4;
		}
		.row.nested {
			margin-top: $base-margin*2;
			p {
				width: 100%;
			}
		}
	}
	
	.col {
		padding: $base-margin*2 $base-margin;
	}
	
	.headline-bottom {
		color: $grey-dark;
	}
	
	.headline-top,
	.headline-bottom {
		@extend %bold-header;
		br {display: none;}
	}
	
	.col-left {
		border-bottom: 4px solid $grey-light;
		.headline-top {
			line-height: 0.65;
			margin-bottom: $base-margin;
			color: $blue;
		}
		.img-area {
			margin-top: $base-margin;
			img {
				margin-left: 0;
				margin-right: 0;
				max-width: 97%;
				box-shadow: 1.250rem 1.250rem 0 $grey-light;
			}
		}
	}

	.col-right {
		.headline-bottom {
			color: $red;
			line-height: 0.65;
			margin-bottom: $base-margin*2;
		}
		.headline-top {
			margin-bottom: $base-margin;
			color: $grey-dark;
		}
		.img-area {
			display: none;
		}
	}
	
	
}

// ========================================
// SM *MIN-WIDTH*
// ========================================
@include breakpoint(sm) {
	
	// ==========
	// ROW 1
	// ==========
	#row1 {
		background-blend-mode: normal;
	}//row1
	
	// ==========
	// ROW 2
	// ==========
	#row2 {
		border-top: 1px solid #e5e5e5;
		.col-bkg {min-height: 0;}
	}//row2
	
	// ==========
	// ROW 3
	// ==========
	#row3 {
		background-blend-mode: normal;
		border-top: 1px solid #e5e5e5;
		border-bottom: 1px solid #e5e5e5;
	}//row3
	
	// ==========
	// ROW 4
	// ==========
	#row4 {
		.col {margin-bottom: 0;}
	}//row4
	
	// ==========
	// ROW 5
	// ==========
	#row5 {

	}//row5
	
}//sm

// ========================================
// MD *MIN-WIDTH*
// ========================================
@include breakpoint(md) {
	
	// ==========
	// FRONT ROW
	// ==========
	.front-row {
		.h1,h1 {
			font-size: $front-header-h1-font-size;
		}
		.h2,h2 {
			font-size: $front-header-h2-font-size;
		}
	}//front-row
	
	// ==========
	// ROW 1
	// ==========
	#row1 {
		.cta-text {
			margin-bottom: 0;
		}
	}//row1	
	
	// ==========
	// ROW 3
	// ==========
	#row3 {
		.col-list {
			padding-left: 0;
			margin-top: 0;
		}
	}//row3
	
	// ==========
	// ROW 4
	// ==========
	#row4 {
		padding-bottom: $front-page-row;
		padding-top: $front-page-row;
		background-position: 90% 0;
	}//row4
	
	// ==========
	// ROW 5
	// ==========
	#row5 {
		
		.inside-top {
			margin-bottom: $base-margin;
			border-bottom: none;
		}
		
		.col-right {
			border-bottom: none;
			.img-area {
				margin-top: -$base-margin*2;
				margin-left: -$base-margin*4;
				display: block;
			}
		}
		
		.col-left {
			border-bottom: none;
			border-right: 4px solid $grey-light;
			.img-area {
				img {
					max-width: 90%;
					margin-left: auto;
					margin-right: auto;
				}
			}
		}
		
		.headline-top,
		.headline-bottom {
			br {display: block;}
		}
		
	}//row5 
	
}//md

// ========================================
// LG *MIN-WIDTH*
// ========================================
@include breakpoint(lg) {
	
	// ==========
	// FRONT ROW
	// ==========
	.front-row {
		padding-bottom: $front-page-row;
		padding-top: $front-page-row;
	}//front-row
	
	// ==========
	// ROW 1
	// ==========
	#row1 {
		.row-top {
			margin-bottom: $front-page-row;
			margin-top: $front-page-row;
		}
		.col-right {
			padding-left: 0;
			padding-right: 0;
			.btn {
				display: block;
			}
		}
		.row1-cta {
			display: block;
			width: 1024px;
			white-space: nowrap;
			background: none;
			position: relative;
			margin-left: 0;
			margin-right: 0;
			padding-right: 0;
			padding-bottom: 1.125rem;
			padding-top: 1.125rem;
			z-index: 3;
			&:before {
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				left: -1000%;
				right: 0;
				background: rgba($red-dark,0.85);
				z-index: -1
			}
		}
	}//row1
	
	// ==========
	// ROW 2
	// ==========
	#row2 {
		.col-text {
			padding-left: $base-margin*2;
			padding-right: $base-margin*2;
		}
		.address-number {
			margin-top: -$base-margin*2;
		}
		hr {
			margin: $base-margin*2 0;
		}
		h2.headline {
			margin-top: -$base-margin*3.25;
			opacity: 0.9;
		}
		.cta-wrap {
			margin-bottom: -$base-margin*2;
		}
	}//row2
	
	// ==========
	// ROW 3
	// ==========
	#row3 {
		padding-bottom: $front-page-row*0.6;
		padding-top: $front-page-row*0.6;
	}//row3
	
	// ==========
	// ROW 4
	// ==========
	#row4 {
		background-position: top right;
	}//row4
	
	// ==========
	// ROW 5
	// ==========
	#row5 {
		
		.inside-top {
			margin-bottom: $front-page-row - $base-margin;
		}
		
		.inside-bottom {
			.col {
				padding: $base-margin*3.5;
			}
		}
		
		.text-wrap {
			margin: 0 auto;
			max-width: 80%;
		}
		.col-left {
			.headline-top {
				margin-top:-$base-margin*3; 
				color: $blue;
			}
			.img-area {
				margin-left: -$base-margin*2;
				margin-right: -$base-margin*5;
				img {
					max-width: 100%;
				}
			}
		}
		.col-right {
			.img-area {
				margin-top: -$base-margin*4.25;
				margin-left: -$base-margin*6.25;
			}
			.headline-top,
			.headline-bottom {
				text-align: right;
			}
		}

		.col {
			padding-left: $base-margin*3;
			padding-right: $base-margin*3;
		}
		
	}//row5
	
}//lg