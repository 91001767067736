// *****************************************
// FORMS
// *****************************************

// ==================================
// TEXT-FIELD, TEXTAREA
// ==================================
.wpcf7-text,
.wpcf7-textarea, {
	@extend %base-form;
	appearance: textfield;
	padding-left: 0.625rem;
}//text-field, textarea

// ==================================
// TEXTAREA
// ==================================
.wpcf7-textarea {
	height: 9.375rem; //150px
	padding: 0.625rem;
	line-height: 1.5;
}//textarea

// ==================================
// SELECT DROPDOWN
// ==================================
.wpcf7-select {
	@extend %base-form;
	padding-right: 3.125rem;
	position: relative;
	z-index: 2;
	cursor: pointer;
	padding-left: 0.625rem;
}//select-dropdown

// FF BORDER FIX
select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}

.form-item.form-type-select {
	.wpcf7-form-control-wrap {
		@extend .icon-down;
		@extend .fontello-icon;
		clear: both;
		position: relative;
		display: block;
		&:before {
			@extend %transition;
			display: block;
			position: absolute;
			bottom: 0;
			right: 0;
			width: 3.125rem;
			height: 3.125rem;
			font-size: 1.875rem;
			line-height: 3.125rem;
			background-color: $black;
			z-index: 5;
			color: $white;
			pointer-events: none;
		}
		&:hover {
			&:before {
				background: #000;
			}
		}
	}//.wpcf7-form-control-wrap
}//.form-item.form-type-select

// ==================================
// FORM SUBMIT
// ==================================
.form-submit {
	@include button;
}//form-submit

// ==================================
// FORM ITEM
// ==================================
.form-item {
	@extend %block;
	label {
		@extend %bold-header;
		font-size: 1.5rem;
		display: block;
		clear: both;
		margin-bottom: 0.313rem;
		.req {color: $red;}
	}
	.description {
		clear: both;
		font-size: $base-small-font-size;
		margin-top: 5px;
	}
}//form-item

// ==================================
// MESSSAGES
// ==================================
.ajax-loader,
.wpcf7-response-output {display: none !important;}

span.wpcf7-not-valid-tip {
	background: $red !important;
	color: $white !important;
	padding: 10px !important;
	margin: -1px 0 0 0 !important;
	line-height: 1.1 !important;
	text-transform: uppercase !important;
	font-size: 16px !important;
}