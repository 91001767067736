// *****************************************
// MIXINS
// *****************************************

// =========================================
// BREAKPOINT
// =========================================
@mixin breakpoint($point) {
	@if $point == xs {
    @media (min-width: $viewport-xs) { @content; }
  }
  @if $point == sm {
    @media (min-width: $viewport-sm) { @content; }
  }
  @else if $point == md {
    @media (min-width: $viewport-md) { @content; }
  }
  @else if $point == lg {
    @media (min-width: $viewport-lg) { @content; }
  }
}//mixin: breakpoint

// =========================================
// LIST
// =========================================
@mixin list($type) {
	clear: both;
	margin-bottom: $base-margin;
	
	@if $type == bullet {
		li {
			list-style-type: disc;
			@extend %list-item;
			li {
				list-style-type: circle;
			}
		}
	}
	
	@if $type == digit {
		li {
			list-style-type: decimal;
			@extend %list-item;
			li {list-style-type: circle;}
		}
	}
	
	@else if $type == inline {
		margin: -0.313rem;
		li {
			display: inline-block;
			position: relative;
			list-style-type: none;
			margin: 0.313rem;
		}
	}
	
	@else if $type == block {
		margin: 0;
		padding: 0;
		display: block;
		clear: both;
		width: 100%;
		list-style-type: none;
		li {
			list-style-type: none;
			display: block;
			clear: both;
			margin: 0;
			padding: 0;
			width: 100%;
			ul {
				margin: 0;
				padding: 0;
			}
		}
	}
	
}

// =========================================
// INSIDE WRAP
// =========================================
@mixin inside($display:block) {
	max-width: $viewport-lg;
	width: auto;
	position: relative;
	display: block;
	margin-left: auto;
	margin-right: auto;
	
	@if $display == "flex" {display: flex;}
	
}//mixin: inside

// =========================================
// TRANSITION
// =========================================
@mixin transition($length:0.3s) {
	transition-property: all;
	transition-timing-function: ease-in-out;
	transition-duration: $length;
}//mixin: transition

// =========================================
// BUTTONS
// =========================================
@mixin button($size:med) {
	display: inline-block;
	cursor: pointer;
	border: none;
	outline: none;
	appearance: none;
	text-align: center;
	border-radius: 0;
	text-decoration: none;
	font-size: 0.938em;
	font-weight: $base-cta-font-weight;
	text-transform: uppercase;
	line-height: 1;
	letter-spacing: 0.188rem;
	padding: $base-margin 2.250rem 1.438rem 2.250rem;
	@extend %transition;
	&:hover {
		text-decoration: none;
	}
	
	@if $size == 'xs' {
		// not available
	}
	
	@if $size == 'sm' {
		// not available
	}
	
	@else if $size == 'med' {
		// default
	} 
	
	@else if $size == 'lg' {
		// not available
	}
	
}//mixin: button

// =========================================
// IMAGES
// =========================================
@mixin img($style:Inline) {
	border: 0;
	outline: none;
	height: auto;
	@if $style == 'Inline' {
		display: inline;
		width: auto;
		max-width: 100%;
	}//inline
	@else if $style == 'Block'{
		display: block;
		width: 100%;
		max-width: none;
	}//block
}//images